<template>
  <div class="container1">
    <div class="guide" v-if="!more">
      <div class="step0">
        <SvgIcon name="step_checked" size="20" />
      </div>
      <div class="line"></div>
      <div class="step1">
        <div class="num">2</div>
        <div class="step1-text">添加我的第 1 个链接</div>
      </div>
    </div>
    <div v-else style="height:0.4rem"></div>

    <div class="content">
      <div class="title">选择要添加的平台</div>
      <div class="desc">点击添加后，该平台将展示在我的主页</div>
      <div style="height: 0.16rem"></div>

      <vue-seamless-scroll :data="socials0" :class-option="classOption" class="warp">
        <ul class="ul-item">
          <li class="li-item" v-for="(item, index) in socials0" :key="index">
            <div class="icon-bg" @click="chooseSocial(item)">
              <div class="icon" :style="{ backgroundImage: `url(${item.logo})` }" alt="" />
            </div>

          </li>
        </ul>
      </vue-seamless-scroll>
      <!-- <div style="height: 0.12rem"></div> -->
      <vue-seamless-scroll :data="socials0" :class-option="classOption" class="warp" ref="centerlist">
        <ul class="ul-item">
          <li class="li-item" v-for="(item, index) in socials1" :key="index">
            <div class="icon-bg" @click="chooseSocial(item)">
              <div class="icon icon1" :style="{ backgroundImage: `url(${item.logo})` }" alt="" />
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
      <!-- <div style="height: 0.12rem"></div> -->
      <vue-seamless-scroll :data="socials0" :class-option="classOption" class="warp">
        <ul class="ul-item">
          <li class="li-item" v-for="(item, index) in socials2" :key="index">
            <div class="icon-bg" @click="chooseSocial(item)">
              <div class="icon icon2" :style="{ backgroundImage: `url(${item.logo})` }" alt="" />
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
      <a hover class="more" @click="tapMore">搜索更多</a>
    </div>
    <div style="height:2.45rem;flex-shrink: 0;"></div>
    <div class="footer" :style="{ height: more ? '1.28rem' : '1.7rem' }">
      <a class="link_btn0" @click="next" style="flex-shrink:0">下一步</a>
      <div style="height:0.32rem;flex-shrink: 0;"></div>
      <a style="color:#74747AFF;flex-shrink: 0;" v-if="!more" @click="later">稍后添加</a>
    </div>

  </div>
</template>

<script>
import { get, post } from '../config/http';
import api from '../config/api';
import vueSeamlessScroll from 'vue-seamless-scroll'
import { nextTick } from 'vue';
import { glob } from 'glob';
export default {
  name: '',
  components: {
    // marquee,
    vueSeamlessScroll
  },
  data() {
    return {
      speed: 50,
      duration: 0,
      duration1: 0,
      duration2: 0,
      socials0: [],
      socials1: [],
      socials2: [],
      more: false,
      items: ['文字1  ', '文字2  ', '文字3  ', '文字4  ', '文字5  ', '文字6  '],
      items2: ['文字1  ', '文字2  ', '文字3  ', '文字4  ', '文字5  ', '文字6  ', '文字1  ', '文字2  ', '文字3  ', '文字4  ', '文字5  ', '文字6  '],
      classOption: {
        limitMoveNum: 2,
        direction: 2,
        switchDelay: 800,
        step: 0.1,
        openTouch: false,
        hoverStop: false
      }
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    var width = 550;
    this.duration = (width * this.items.length) / this.speed + 's';
    this.duration1 = (width * this.items2.length) / this.speed + 's';
    this.duration2 = (width * this.items.length) / this.speed + 's';
    this.more = this.$route.query.more ? this.$route.query.more : false
    this.getDatas();
  },
  methods: {
    getDatas() {
      var array0 = [],
        array1 = [],
        array2 = [];
      var _this = this;
      get(api.topSocials)
        .then((data) => {
          data.map((item, index) => {
            if (index % 3 == 0) {
              array0.push(item);
            } else if (index % 3 == 1) {
              array1.push(item);
            } else {
              array2.push(item);
            }
          });
          array0 = array0.concat(array0);
          while (array0.length < 6) {
            array0 = array0.concat(array0);
          }
          while (array1.length < 6) {
            array1 = array1.concat(array1);
          }
          while (array2.length < 6) {
            array2 = array2.concat(array2);
          }
          _this.socials0 = array0;
          _this.socials1 = array1;
          _this.socials2 = array2;

          nextTick(() => {
            _this.$refs.centerlist.xPos = -50
          })
        })
        .catch((e) => { });
    },
    tapMore() {
      this.$router.push({ path: '/platform/all', query: { more: this.more } }).catch(err=>{})
    },
    next() {
      this.$toast('请选择平台并添加链接和标题', require('../assets/images/toast_tip.png'))
      // this.$router.push('/add_link')
    },
    chooseSocial(item) {
      localStorage.setItem('lastSocial', JSON.stringify(item))
      this.$router.push({ path: '/platform/link', query: { more: this.more } }).catch(err=>{})
    },
    later() {
      if (global.index) {
        global.index.refresh()
      }
      global.hasLogin = true
      window.localStorage.setItem('hasLogin', '1')
      this.$router.push('/').catch(err=>{})
    },
    updateLoginStep() {
      this.$post(this.$api.frontData, {
        code: 'profile_completed_step',
        value: 2.2
      }).then(data => {
        global.hasLogin = true
        window.localStorage.setItem('hasLogin', '1')
        this.$router.push('/').catch(err=>{})
      })
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  color: #fff;
  background-color: #131314;
}

h1 {
  font-size: 0.2rem;
}

.guide {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.18rem;
  margin-bottom: 0.22rem;

  .step0 {

    width: 0.44rem;
    height: 0.44rem;
    border-radius: 0.22rem;
    border: solid 0.04rem #74747AFF;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .line {
    margin-left: -1px;
    width: 0.6rem;
    height: 0.08rem;
    background: linear-gradient(90deg, #74747A 0%, #FFF700 100%);
  }

  .step1 {
    margin-left: -1px;
    height: 0.44rem;
    background: #000000;
    box-shadow: 0rem 0.14rem 0.2rem 0rem rgba(255, 247, 0, 0.4), 0rem 0.08rem 0rem -0.02rem #FFF700;
    border-radius: 0.22rem;
    border: 0.04rem solid #FFF700;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.04rem;
    padding-right: 0.16rem;

    .num {
      width: 0.28rem;
      height: 0.28rem;
      background: #FFF700;
      border-radius: 0.14rem;
      color: #131314FF;
      font-size: 0.2rem;
      font-weight: bold;
      line-height: 0.28rem;
      font-family: Link;
    }

    .step1-text {
      color: #FFF700;
      margin-left: 0.06rem;
      font-size: 0.14rem;
      font-weight: bold;
    }
  }
}

.content {
  width: 3.43rem;
  background-color: #2c2c2eff;
  border-radius: 0.24rem;
  padding: 0.22rem 0 0.12rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  flex-shrink: 0;

  .title {
    margin-left: 0.2rem;
    font-size: 0.2rem;
    font-weight: 600;
    color: #fff700;
    line-height: 0.28rem;
  }

  .desc {
    margin-left: 0.2rem;
    margin-top: 0.04rem;
    font-size: 0.12rem;
    color: #a5a5ad;
    line-height: 0.17rem;
  }


  .more {
    margin-top: 0.04rem;
    height: 0.52rem;
    text-align: center;
    color: #FF9900FF;
    font-size: 0.14rem;
    line-height: 0.52rem;
    width: 100%;
  }
}







.icon {
  width: 0.72rem;
  height: 0.72rem;
  overflow: hidden;
  border-radius: 0.18rem;
  margin-right: 0.12rem;
  background-size: 0.72rem 0.72rem;
  background-repeat: no-repeat;
}

.icon-bg {
  padding-bottom: 0.12rem;
  flex-shrink: 0;
}

.icon-bg:active {
  transform: scale(0.95);
}


.warp {
  width: 3.43rem;
  height: 0.84rem;
  margin: 0 auto;
  overflow: hidden;
  flex-shrink: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    overflow: hidden;

    &.ul-item {
      display: flex;
      overflow: hidden;

      .li-item {
        width: 0.84rem;
        height: 0.84rem;
        line-height: 0.72rem;
        text-align: center;
      }
    }
  }
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.32rem;
  box-sizing: border-box;
  background-image: linear-gradient(180deg, rgba(19, 19, 20, 0.00) 0%, #131314 25%);
}
</style>